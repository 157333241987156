import {
    DependentRequired,
    Format,
    JsonSchema,
    Optional,
    Required,
} from 'ts-decorator-json-schema-generator';
import { DateTimeZone } from '@lib/shared-interface-utility-types';

// This is functionally equivalent to `DateTimeZone`.
// However, we cannot implement that particular union,
// so we implement `SimpleDateTimeZone` instead.
@JsonSchema({
    title: 'Date-Time-Zone Schema',
    description: 'An object specifying a selected date with varying levels of precision.',
})
export class DateTimeZoneSchema implements DateTimeZone {
    @Required()
    @Format('date')
    public readonly date!: string;

    @Optional()
    @Format('time')
    @DependentRequired('date')
    public readonly time?: string;

    // Todo: is it worthwhile to further validate this value?
    // The constraint of "timezone" not existing without "time"
    // is implemented by using json-schema "dependentRequired".
    // See https://json-schema.org/understanding-json-schema/reference/conditionals.html#id4.
    @Optional()
    @DependentRequired('time')
    public readonly timezone?: string;
}
