import { DateTime } from 'luxon';

/**
 * A "variable precision date time" where date, time, and timezone are specified.
 *
 * Time and timezone are optional, but without time, timezone will be ignored.
 */
export interface DateTimeZone {
    /**
     * ISO date string but only the date portion.
     */
    date: string;
    /**
     * ISO date string but only the time portion.
     */
    time?: string;
    /**
     * Timezone name in case that is important to the completion date.
     */
    timezone?: string;
}

export function toDateTime(dateTimeZone: DateTimeZone): DateTime {
    if (!dateTimeZone.time) {
        return DateTime.fromISO(dateTimeZone.date)
            .endOf('day')
            .setZone('UTC', { keepLocalTime: true });
    }

    if (!dateTimeZone.timezone) {
        const datePart = DateTime.fromISO(dateTimeZone.date).toISODate();
        const timePart = DateTime.fromISO(dateTimeZone.time).toISOTime();
        return DateTime.fromISO(`${datePart}T${timePart}`);
    }

    const datePart = DateTime.fromISO(dateTimeZone.date).toISODate();
    const timePart = DateTime.fromISO(dateTimeZone.time)
        .setZone(dateTimeZone.timezone, { keepLocalTime: true })
        .toISOTime();
    return DateTime.fromISO(`${datePart}T${timePart}`, { zone: dateTimeZone.timezone });
}
