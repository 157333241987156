import { GraphqlQuery } from './graphql-query';
import { gql } from 'graphql-tag';
import { Inject, Injectable } from '@angular/core';
import { GRAPHQL_DOMAIN_URL_TOKEN, GraphqlDomain } from '../provide-graphql-configuration';
import { HttpClient } from '@angular/common/http';
import { Email, Uuid } from '@lib/shared-interface-utility-types';

@Injectable({ providedIn: 'root' })
export class GetAuthenticatedAccountGql extends GraphqlQuery<undefined, Result> {
    public readonly document = gql`
        query GetAuthenticatedAccount {
            authenticatedAccount {
                id
                label
                firstName
                lastName
                email
                createdAt
                active
            }
        }
    `;

    public constructor(
        @Inject(GRAPHQL_DOMAIN_URL_TOKEN) graphqlDomain: GraphqlDomain,
        http: HttpClient,
    ) {
        super(graphqlDomain, http);
    }
}

type Result = Record<
    'authenticatedAccount',
    {
        id: Uuid;
        label: string;
        firstName: string;
        lastName: string;
        email: Email;
        createdAt: number;
        updatedAt: number;
        active: boolean;
    }
>;
