import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SideMenuService {
    private sideMenuOpen = new BehaviorSubject(true);
    public sideMenuOpen$ = this.sideMenuOpen.asObservable();

    public toggleOpen() {
        this.sideMenuOpen.next(!this.sideMenuOpen.value);
    }

    public setOpen(open: boolean) {
        this.sideMenuOpen.next(open);
    }
}
