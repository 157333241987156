import { Provider } from '@angular/core';
import { provideAppInitializer } from '@lib/didit-shared-util-miscellaneous-providers';
import { addIcons } from 'ionicons';
import {
    add,
    addCircle,
    addCircleOutline,
    addOutline,
    alertCircle,
    arrowBack,
    arrowBackCircleOutline,
    arrowForwardCircleOutline,
    businessSharp,
    calendar,
    calendarNumberOutline,
    call,
    chatboxEllipsesOutline,
    chatbubblesOutline,
    checkmark,
    checkmarkCircle,
    checkmarkCircleOutline,
    checkmarkDoneOutline,
    checkmarkOutline,
    chevronDownOutline,
    close,
    closeCircle,
    closeOutline,
    compassOutline,
    create,
    createOutline,
    documentTextOutline,
    earOutline,
    fileTray,
    fileTrayFullOutline,
    fileTrayOutline,
    globeOutline,
    handLeft,
    handLeftOutline,
    helpOutline,
    image,
    informationCircleOutline,
    mailOutline,
    menu,
    newspaperOutline,
    openOutline,
    options,
    peopleOutline,
    personCircle,
    personCircleOutline,
    personOutline,
    pricetagOutline,
    repeatOutline,
    search,
    send,
    sendOutline,
    shareOutline,
    thumbsDownSharp,
    thumbsUpSharp,
    timeOutline,
    timerOutline,
    trash,
    trashOutline,
} from 'ionicons/icons';

export const ICONS_INITIALIZER: Provider = provideAppInitializer(initializeIcons);

function initializeIcons() {
    return () => {
        return addIcons({
            add,
            addCircle,
            addCircleOutline,
            addOutline,
            alertCircle,
            arrowBack,
            arrowBackCircleOutline,
            arrowForwardCircleOutline,
            assign: '/assets/icons/assign.svg',
            businessSharp,
            calendar,
            calendarNumberOutline,
            call,
            chatboxEllipsesOutline,
            chatbubblesOutline,
            checkmark,
            checkmarkCircle,
            checkmarkCircleOutline,
            checkmarkDoneOutline,
            checkmarkOutline,
            chevronDownOutline,
            close,
            closeCircle,
            closeOutline,
            compassOutline,
            create,
            createOutline,
            documentTextOutline,
            earOutline,
            fileTray,
            fileTrayFullOutline,
            fileTrayOutline,
            globeOutline,
            handLeft,
            handLeftOutline,
            helpOutline,
            image,
            informationCircleOutline,
            mailOutline,
            menu,
            newspaperOutline,
            openOutline,
            options,
            peopleOutline,
            permanentDeleteOutline: '/assets/icons/permanent-delete-outline.svg',
            personCircle,
            personCircleOutline,
            personOutline,
            pricetagOutline,
            repeatOutline,
            restoreTrashOutline: '/assets/icons/restore-trash-outline.svg',
            search,
            send,
            sendOutline,
            shareOutline,
            thumbsDownSharp,
            thumbsUpSharp,
            timeOutline,
            timerOutline,
            trash,
            trashOutline,
        });
    };
}
