import {
    Enum,
    Items,
    JsonSchema,
    Optional,
    Required,
    Type,
} from 'ts-decorator-json-schema-generator';
import { Assignment } from '@lib/shared-interface-assignment';
import { UuidFormat } from '../../shared/uuid-format.decorator';
import { Uuid } from '@lib/shared-interface-utility-types';
import { RecipientAskStatus, RecipientAskStatusChange } from '@lib/shared-interface-ask';
import { generateRxdbSchema } from '../../shared/generate-rxdb-schema';
import { RecipientStatusChangesSchema } from '../asks-collection/recipient-status-changes.schema';

@JsonSchema({
    title: 'Assignment Schema',
    description: 'Data necessary for tracking and completing an assignment.',
})
class AssignmentsSchema implements Assignment {
    @Required()
    @UuidFormat()
    public readonly id!: Uuid;

    @Required()
    @UuidFormat()
    public readonly askId!: Uuid;

    @Required()
    @UuidFormat()
    public readonly creatorId!: Uuid;

    @Optional()
    @UuidFormat()
    public readonly contactId?: Uuid;

    @Optional()
    @UuidFormat()
    public readonly accountId?: Uuid;

    @Optional()
    public readonly resolutionSummary?: string;

    @Required()
    @Items(RecipientStatusChangesSchema)
    public readonly statusChanges!: RecipientAskStatusChange[];

    @Required()
    @Enum(RecipientAskStatus)
    public readonly currentStatus!: RecipientAskStatus;

    @Required()
    @Type('integer')
    public readonly createdAt!: number;

    @Required()
    @Type('integer')
    public readonly updatedAt!: number;
}

export const ASSIGNMENT_SCHEMA = generateRxdbSchema(AssignmentsSchema, {
    primaryKey: 'id',
    // If you do not increment this version, you may need to wipe your database.
    // Once in production, incrementing this number will require migrations.
    version: 0,
    // Key compression doesn't work with fake-indexeddb, so it is turned off for now.
    // If we need key compression at some point, we can probably selectively turn it off for tests.
    keyCompression: false,
});
