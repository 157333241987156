import { Inject, Injectable } from '@angular/core';
import { GraphqlMutation } from './graphql-mutation';
import { gql } from 'graphql-tag';
import { GRAPHQL_DOMAIN_URL_TOKEN, GraphqlDomain } from '../provide-graphql-configuration';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class DowngradePlanToBasicGql extends GraphqlMutation<undefined, Result> {
    public override readonly document = gql`
        mutation DowngradePlanToBasic {
            downgradePlanToBasic {
                success
            }
        }
    `;

    public constructor(
        @Inject(GRAPHQL_DOMAIN_URL_TOKEN) graphqlDomain: GraphqlDomain,
        http: HttpClient,
    ) {
        super(graphqlDomain, http);
    }
}

type Result = Record<'downgradePlanToBasic', { success: boolean }>;
