import { Format, MaxLength } from 'ts-decorator-json-schema-generator';

/**
 * A decorator to apply common decorators for a V4 UUID.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function UuidFormat() {
    // This can eventually be validated with AJV.
    const format = Format('uuid');

    // If this is used for a primary key, a max length is required:
    // https://rxdb.info/rx-schema.html#example
    const maxLength = MaxLength(36);

    return composeJsonSchemaDecorators(format, maxLength);
}

/**
 * Compose multiple decorators into a single decorator.
 * @param decorators the list of decorators to compose.
 */
function composeJsonSchemaDecorators(...decorators: JsonSchemaDecorator[]): JsonSchemaDecorator {
    return (target, propertyKey) => {
        for (const decorator of decorators) decorator(target, propertyKey);
    };
}

type JsonSchemaDecorator = (target: unknown, propertyKey?: string | undefined) => void;
