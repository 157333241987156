// eslint-disable-next-line unicorn/prevent-abbreviations
import { addRxPlugin } from 'rxdb';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';

export function addProductionPlugins() {
    // Adds multi-tab support.
    addRxPlugin(RxDBLeaderElectionPlugin);
    // Adds cleanup policies for created databases.
    addRxPlugin(RxDBCleanupPlugin);
    // Adds mongo-like update syntax
    addRxPlugin(RxDBUpdatePlugin);
}

export const getProductionStorage = () => getRxStorageDexie();
