import { Uuid } from '@lib/shared-interface-utility-types';
import { createContactReplicationOptions } from './contacts-collection/create-contact-replication-options';
import { replicateGraphQL, RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql';
import { AccountDatabase } from './get-account-database';
import { createAccountReplicationOptions } from './account-collection/create-account-replication-options';
import { createAskReplicationOptions } from './asks-collection/create-ask-replication-options';
import { createAssignmentReplicationOptions } from './assignments-collection/create-assignment-replication-options';
import { CommonSyncOptions } from '../app-database/app-database-replication-helpers';
import { RxGraphQLReplicationPullQueryBuilder, RxGraphQLReplicationPushQueryBuilder } from 'rxdb';
import { ReplicationPushRow } from '@lib/shared-interface-rxdb-replication-types';

export function getPullQueryBuilder<Checkpoint>(
    query: string,
    constants: {
        accountId: Uuid;
        limit?: number;
    },
): RxGraphQLReplicationPullQueryBuilder<Checkpoint> {
    const { accountId, limit } = constants;
    return (checkpoint?: Checkpoint) => {
        return { query, variables: { accountId, checkpoint, limit } };
    };
}

export function getPushQueryBuilder<ReplicatedDocument>(
    query: string,
    constants: {
        accountId: Uuid;
    },
): RxGraphQLReplicationPushQueryBuilder {
    const { accountId } = constants;
    return (rows: ReplicationPushRow<ReplicatedDocument>[]) => {
        return { query, variables: { accountId, rows } };
    };
}

export function getPullStreamQueryBuilder(
    query: string,
    constants: {
        accountId: Uuid;
    },
) {
    const { accountId } = constants;
    // Headers only need to be used if we reach the point of authenticating individual accounts.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (headers: Record<string, string>) => {
        const authorization = headers['authorization'];
        return { query, variables: { accountId, authorization } };
    };
}

const REPLICATION_STATES = new Map<Uuid, RxGraphQLReplicationState<unknown, unknown>[]>();

export function startAccountDatabaseReplication(
    accountId: Uuid,
    database: AccountDatabase,
    commonOptions: CommonSyncOptions,
): void {
    // Replication states might already exist.
    let replicationStates = REPLICATION_STATES.get(accountId);
    if (replicationStates != undefined) {
        const headers = commonOptions.headers ?? {};
        for (const state of replicationStates) state.setHeaders(headers);
        return;
    }

    const collectionReplicationOptions: CollectionSpecificSyncOptions[] = [
        createAccountReplicationOptions(accountId, database.account),
        createAskReplicationOptions(accountId, database.asks),
        createAssignmentReplicationOptions(accountId, database.assignments),
        createContactReplicationOptions(accountId, database.contacts),
    ];
    const startReplication = (options: CollectionSpecificSyncOptions) => {
        const fullReplicationOptions = { ...options, ...commonOptions };
        return replicateGraphQL(fullReplicationOptions);
    };
    replicationStates = collectionReplicationOptions.map(startReplication);

    REPLICATION_STATES.set(accountId, replicationStates);
}

export async function completeInitialAccountReplication(id: Uuid): Promise<void> {
    const states = REPLICATION_STATES.get(id);
    if (!states) throw new Error('No replication states found');

    const completeReplication = (state: RxGraphQLReplicationState<unknown, unknown>) =>
        state.awaitInitialReplication();
    const completedReplications = states.map(completeReplication);
    await Promise.all(completedReplications);
}

export async function stopAccountDatabaseReplication(accountId: Uuid): Promise<void> {
    // Pull up the replication state for this account and cancel replication.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const replicationStates = REPLICATION_STATES.get(accountId);
    if (replicationStates == undefined) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    const cancellations = replicationStates.map((state) => state.cancel());
    await Promise.allSettled(cancellations);

    REPLICATION_STATES.delete(accountId);
}

// Todo: should be `SyncOptionsGraphQL<unknown, unknown>`
//  but `SyncOptionsGraphQL` currently isn't exported correctly from rxdb.
type SyncOptionsGraphQl = Parameters<typeof replicateGraphQL>[0];
type CollectionSpecificSyncOptions = Omit<
    SyncOptionsGraphQl,
    'url' | 'headers' | 'replicationIdentifier'
>;
