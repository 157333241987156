import { AppDatabase, getAppDatabase } from './app-database/get-app-database';
import { BehaviorSubject, filter, from, shareReplay } from 'rxjs';
import { Account } from '@lib/shared-interface-account';
import { AccountReference } from '@lib/didit-accounts-interface-account-reference';
import { AccountDatabase, getAccountDatabase } from './account-database/get-account-database';
import { Uuid } from '@lib/shared-interface-utility-types';
import { MangoQueryNoLimit, RxChangeEvent } from 'rxdb';
import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
    completeInitialAccountReplication,
    startAccountDatabaseReplication,
    stopAccountDatabaseReplication,
} from './account-database/account-database-replication-helpers';
import { GRAPHQL_DOMAIN_URL_TOKEN } from '@lib/didit-authentication-graphql-client';
import {
    CommonSyncOptions,
    startAppDatabaseReplication,
    stopAppDatabaseReplication,
} from './app-database/app-database-replication-helpers';
import { AccountDatabaseJanitor } from './account-database/account-database-janitor';
import { Duration } from 'luxon';
import { DATABASE_SERVICE_OPTIONS_TOKEN } from './provide-database-service';

const cleanupFrequency = Duration.fromObject({ day: 1 }).as('millisecond');
const maxAge = Duration.fromObject({ month: 1 }).as('millisecond');

@Injectable({ providedIn: 'root' })
export class DatabaseService {
    private readonly databaseOptions = inject(DATABASE_SERVICE_OPTIONS_TOKEN);
    private readonly document = inject(DOCUMENT);
    private readonly graphqlDomain = inject(GRAPHQL_DOMAIN_URL_TOKEN);

    // Making this a signal allows us to externally reconfigure these values as needed later on.
    // Todo: we need to determine if these options will be configured at the account level
    //  or at the application level.
    private readonly trashPolicy = new BehaviorSubject({ cleanupFrequency, maxAge });

    public readonly appDatabase$ = from(this.getAppDatabase()).pipe(shareReplay(1));

    public async getAppDatabase(): Promise<AppDatabase> {
        return getAppDatabase(this.databaseOptions.getStorage);
    }

    public async getAccountDatabase(accountId: Uuid): Promise<AccountDatabase> {
        return getAccountDatabase(accountId, this.databaseOptions.getStorage);
    }

    public async startAppDatabaseReplication(): Promise<void> {
        // Use proxied graphql endpoints for queries, mutations, and subscriptions.
        const { origin } = this.document.location;
        const httpOrigin = this.graphqlDomain || origin;
        const wsOrigin = httpOrigin.replace(/^http/, 'ws');
        const graphqlEndpoint = 'graphql';
        const commonOptions: CommonSyncOptions = {
            // Added in v15 - https://rxdb.info/replication.html#replicaterxcollection
            // I'm assuming this can be common to all collections.
            // If not, we may need to set this unqiuely on a per-collection basis.
            replicationIdentifier: `didit-collections-${httpOrigin}`,
            url: {
                http: `${httpOrigin}/${graphqlEndpoint}`,
                ws: `${wsOrigin}/${graphqlEndpoint}`,
            },
        };

        // Pull up the app database.
        const database = await this.getAppDatabase();

        startAppDatabaseReplication(database, commonOptions);
    }

    public stopAppDatabaseReplication() {
        return stopAppDatabaseReplication();
    }

    public async startAccountDatabaseReplication(accountId: Uuid, token: string): Promise<void> {
        // Use proxied graphql endpoints for queries, mutations, and subscriptions.
        const { origin } = this.document.location;
        const httpOrigin = this.graphqlDomain || origin;
        const wsOrigin = httpOrigin.replace(/^http/, 'ws');
        const graphqlEndpoint = 'graphql';
        const commonOptions: CommonSyncOptions = {
            // Added in v15 - https://rxdb.info/replication.html#replicaterxcollection
            // I'm assuming this can be common to all collections.
            // If not, we may need to set this unqiuely on a per-collection basis.
            replicationIdentifier: `didit-collections-${httpOrigin}`,
            url: {
                http: `${httpOrigin}/${graphqlEndpoint}`,
                ws: `${wsOrigin}/${graphqlEndpoint}`,
            },
            headers: {
                authorization: token,
            },
        };

        // Pull up account database.
        const database = await this.getAccountDatabase(accountId);

        startAccountDatabaseReplication(accountId, database, commonOptions);
    }

    public stopAccountDatabaseReplication(accountId: Uuid) {
        return stopAccountDatabaseReplication(accountId);
    }

    public async connectToExistingAccounts(): Promise<void> {
        const appDatabase = await this.getAppDatabase();
        const query = appDatabase.account_references.find();
        const accountReferences = await query.exec();
        const connectDatabase = ({ id }: AccountReference) => this.connectAccountDatabase(id);

        const connections = accountReferences.map(connectDatabase);
        await Promise.all(connections);
    }

    public async connectAccountDatabase(id: Uuid): Promise<void> {
        const accountDatabase = await this.getAccountDatabase(id);
        // Awaiting initial replication is only available to the leader.
        if (accountDatabase.isLeader()) await completeInitialAccountReplication(id);

        // Do not await this, because if the current tab is not the leader, it will never complete.
        // But if the current tab later becomes leader, we still want it to fulfill these duties;
        // i.e. don't wrap this in an if like above.
        void this.startLeadershipTasks(accountDatabase, id);
    }

    private async startLeadershipTasks(
        accountDatabase: AccountDatabase,
        accountId: Uuid,
    ): Promise<void> {
        // Logic beyond this point will only occur if this account database is elected the leader.
        // This might never occur if this tab is closed before the current leader is closed.
        await accountDatabase.waitForLeadership();

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { account_references } = await this.getAppDatabase();
        const selectById: MangoQueryNoLimit<AccountReference> = { selector: { id: accountId } };
        const query = account_references.findOne(selectById);
        const accountReference = await query.exec();

        // This is mainly type-guarding; this will never happen.
        if (accountReference == undefined) return;

        const updateAccountReference = (changeEvent: RxChangeEvent<Account>) => {
            const { label, email, firstName, lastName, avatar } = changeEvent.documentData;
            const patchData = { label, email, firstName, lastName, avatar };
            const latestAccountReference = accountReference.getLatest();

            void latestAccountReference.incrementalPatch(patchData);
        };

        // If the account changes and the data duplicated on an account reference is changed,
        // then we get that duplicate data and patch the account reference with it.
        // This way, when syncing or updating locally,
        // the label/email in the account menu always stays up to date.
        accountDatabase.account.$.pipe(filter(isUpdateToAccountReference)).subscribe(
            updateAccountReference,
        );

        const janitor = AccountDatabaseJanitor.createFor(accountDatabase);

        // If we change the trash policy at runtime, such as with a change in account configuration,
        // this effect will re-run and re-start the maintenance cycle.
        this.trashPolicy.subscribe(({ cleanupFrequency, maxAge }) => {
            janitor.startMaintenance(cleanupFrequency, maxAge);
        });
    }
}

function isUpdateToAccountReference(changeEvent: RxChangeEvent<Account>) {
    const { operation, previousDocumentData, documentData } = changeEvent;
    if (operation === 'DELETE') return false;
    if (operation === 'INSERT') return true;

    // operation === 'UPDATE'
    return duplicatedDataChanged(previousDocumentData, documentData);
}

function duplicatedDataChanged(previous: DuplicatedAccountData, current: DuplicatedAccountData) {
    return (
        previous.label !== current.label ||
        previous.avatar !== current.avatar ||
        previous.email !== current.email ||
        previous.firstName !== current.firstName ||
        previous.lastName !== current.lastName
    );
}

type DuplicatedAccountData = Pick<
    AccountReference,
    'label' | 'email' | 'firstName' | 'lastName' | 'avatar'
>;
