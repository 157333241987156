import { Recipient } from './ask.interface';
import { AssignmentStrategy } from './ask-enums.interface';

export function adjustAssignmentStrategy(
    currentStrategy: AssignmentStrategy,
    recipients: Recipient[],
): AssignmentStrategy {
    const assignees = recipients.filter((recipient) => recipient.assignee);
    if (assignees.length < 2) return AssignmentStrategy.EVERYONE;

    return currentStrategy;
}
