import { I18nService } from './i18n.service';
import { HttpClient } from '@angular/common/http';
import { TranslationLoaderService } from './translation-loader.service';
import { I18N_ASSETS_PREFIX, I18N_ASSETS_SUFFIX } from './i18n.constants';
import { provideAppInitializer } from '@lib/didit-shared-util-miscellaneous-providers';
import { Provider } from '@angular/core';

export function provideI18nInitializer(): Provider {
    return provideAppInitializer(createI18nInitializer, I18nService);
}

function createI18nInitializer(i18nService: I18nService): () => Promise<void> {
    return async () => await i18nService.initLanguage();
}

export function createHttpLoaderFactory(http: HttpClient) {
    return new TranslationLoaderService(I18N_ASSETS_PREFIX, I18N_ASSETS_SUFFIX, http);
}
