<lib-branded-navigation>
    <!-- Without tabindex="" this requires two tabs to actually focus the correct element -->
    <ion-button
        desktopNavigationLink
        routerLink="/todo"
        routerLinkActive="selected"
        routerDirection="root"
        tabindex=""
    >
        <ion-label class="ion-hide-md-down">
            {{ 'navigation.primary.todo' | translate }}
        </ion-label>
        <ion-label class="ion-hide-md-up"> Do </ion-label>
    </ion-button>
    <ion-button
        desktopNavigationLink
        routerLink="/watching"
        routerLinkActive="selected"
        routerDirection="root"
        tabindex=""
    >
        <ion-label class="ion-hide-md-down">
            {{ 'navigation.primary.watching' | translate }}
        </ion-label>
        <ion-label class="ion-hide-md-up"> Watch </ion-label>
    </ion-button>
    <ion-button
        desktopNavigationLink
        routerLink="/done"
        routerLinkActive="selected"
        routerDirection="root"
        tabindex=""
    >
        <ion-label> {{ 'navigation.primary.done' | translate }} </ion-label>
    </ion-button>
    <ion-button
        desktopNavigationLink
        routerLink="/explore"
        routerLinkActive="selected"
        routerDirection="root"
        tabindex=""
    >
        <ion-label> {{ 'navigation.primary.explore' | translate }} </ion-label>
    </ion-button>

    <ion-tab-button mobileTabButton tab="todo">
        <ion-label>
            {{ 'navigation.primary.todo' | translate }}
        </ion-label>
        <ion-icon src="/assets/icons/checklist.svg" />
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="watching">
        <ion-label>
            {{ 'navigation.primary.watching' | translate }}
        </ion-label>
        <ion-icon src="/assets/icons/eyeglasses.svg" />
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="dashboard">
        <picture>
            <img alt="didit logo" class="logo-icon" src="/assets/logos/logo-icon-only.svg" />
        </picture>
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="done">
        <ion-label>
            {{ 'navigation.primary.done' | translate }}
        </ion-label>
        <ion-icon name="checkmark-circle-outline" />
    </ion-tab-button>
    <ion-tab-button mobileTabButton tab="explore">
        <ion-label>
            {{ 'navigation.primary.explore' | translate }}
        </ion-label>
        <ion-icon name="compass-outline" />
    </ion-tab-button>

    <ion-list
        sideMenuContent
        [class.desktop]="platformIsDesktop"
        lines="none"
        class="no-safe-area-right"
        [style.padding-bottom]="0"
    >
        <ion-item-group>
            <div class="nav-item-wrapper">
                <ion-item
                    button
                    detail="false"
                    routerLink="/messages"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label class="route-description">
                        {{ 'navigation.mobile.messages' | translate }}
                    </ion-label>
                    <ion-icon name="file-tray-full-outline" slot="start" />
                </ion-item>
                <span class="icon-tooltip">
                    {{ 'navigation.mobile.messages' | translate }}
                </span>
            </div>
            <div class="nav-item-wrapper">
                <ion-item
                    button
                    detail="false"
                    routerLink="/sent"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label class="route-description">
                        {{ 'navigation.mobile.sent' | translate }}
                    </ion-label>
                    <ion-icon name="send-outline" slot="start" />
                </ion-item>
                <span class="icon-tooltip">
                    {{ 'navigation.mobile.sent' | translate }}
                </span>
            </div>
            <!--             TODO: create and add the review page to the routes-->
            <div class="nav-item-wrapper">
                <ion-item
                    button
                    detail="false"
                    routerLink="/review"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label class="route-description">
                        {{ 'navigation.mobile.review' | translate }}
                    </ion-label>
                    <ion-icon name="checkmark-done-outline" slot="start" />
                </ion-item>
                <span class="icon-tooltip">
                    {{ 'navigation.mobile.review' | translate }}
                </span>
            </div>
            <div class="nav-item-wrapper">
                <ion-item
                    button
                    detail="false"
                    routerLink="/drafts"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label class="route-description">{{
                        'navigation.mobile.drafts' | translate
                    }}</ion-label>
                    <ion-icon name="document-text-outline" slot="start" />
                </ion-item>
                <span class="icon-tooltip">
                    {{ 'navigation.mobile.drafts' | translate }}
                </span>
            </div>
            <div class="nav-item-wrapper">
                <ion-item
                    button
                    detail="false"
                    routerLink="/trash"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label class="route-description">{{
                        'navigation.mobile.trash' | translate
                    }}</ion-label>
                    <ion-icon name="trash-outline" slot="start" />
                </ion-item>
                <span class="icon-tooltip">
                    {{ 'navigation.mobile.trash' | translate }}
                </span>
            </div>
            <div class="nav-item-wrapper">
                <ion-item
                    button
                    detail="false"
                    routerLink="/contacts"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label class="route-description">
                        {{ 'navigation.mobile.contacts' | translate }}
                    </ion-label>
                    <ion-icon name="people-outline" slot="start" />
                </ion-item>
                <span class="icon-tooltip">
                    {{ 'navigation.mobile.contacts' | translate }}
                </span>
            </div>
        </ion-item-group>
        <ion-item-divider></ion-item-divider>
        <ion-item-group>
            <ion-accordion-group class="left-nav-accordion">
                <ion-accordion value="first">
                    <ion-item slot="header">
                        <ion-label class="route-description">
                            {{ 'navigation.mobile.helpCenter' | translate }}
                        </ion-label>
                        <span class="icon-tooltip accordion-label">
                            {{ 'navigation.mobile.helpCenter' | translate }}
                        </span>
                    </ion-item>
                    <div class="ion-padding" slot="content">
                        <div class="nav-item-wrapper">
                            <ion-item
                                button
                                detail="false"
                                routerLink="/information/usage"
                                routerLinkActive="selected"
                                routerDirection="root"
                            >
                                <ion-label class="route-description">
                                    {{ 'navigation.mobile.help' | translate }}
                                </ion-label>
                                <ion-icon name="help-outline" slot="start" />
                            </ion-item>
                            <span class="icon-tooltip">
                                {{ 'navigation.mobile.help' | translate }}
                            </span>
                        </div>
                        <div class="nav-item-wrapper">
                            <ion-item
                                button
                                detail="false"
                                routerLink="/information/feedback"
                                routerLinkActive="selected"
                                routerDirection="root"
                            >
                                <ion-label class="route-description">
                                    {{ 'navigation.mobile.feedback' | translate }}
                                </ion-label>
                                <ion-icon name="chatbox-ellipses-outline" slot="start" />
                            </ion-item>
                            <span class="icon-tooltip">
                                {{ 'navigation.mobile.feedback' | translate }}
                            </span>
                        </div>
                        <!--       <div class="nav-item-wrapper">-->
                        <!--            <ion-item-->
                        <!--                button-->
                        <!--                detail="false"-->
                        <!--                routerLink="/information/whats-new"-->
                        <!--                routerLinkActive="selected"-->
                        <!--                routerDirection="root"-->
                        <!--            >-->
                        <!--                <ion-label class="route-description"> {{ 'navigation.mobile.whatsNew' | translate }} </ion-label>-->
                        <!--                <ion-icon name="newspaper-outline" slot="start" />-->
                        <!--            </ion-item>-->
                        <!--            <span class="icon-tooltip">-->
                        <!--                 {{ 'navigation.mobile.whatsNew' | translate }}-->
                        <!--            </span>-->
                        <!--       </div> -->
                        <div class="nav-item-wrapper">
                            <ion-item
                                button
                                detail="false"
                                routerLink="/information/about"
                                routerLinkActive="selected"
                                routerDirection="root"
                            >
                                <ion-label class="route-description">
                                    {{ 'navigation.mobile.about' | translate }}
                                </ion-label>
                                <ion-icon name="information-circle-outline" slot="start" />
                            </ion-item>
                            <span class="icon-tooltip">
                                {{ 'navigation.mobile.about' | translate }}
                            </span>
                        </div>
                    </div>
                </ion-accordion>
            </ion-accordion-group>
        </ion-item-group>
    </ion-list>

    @if (account(); as account) {
        <div class="stacked-icon-button" accountMenuToggle>
            <ion-button>
                <lib-account-avatar slot="icon-only" [account]="account" [width]="28" />
            </ion-button>
            <ion-label color="medium" position="bottom"> {{ account.label }} </ion-label>
        </div>
    } @else {
        <!-- Technically, if you see this, there is an issue.
             The reason is that we shouldn't see a navigation if there is no active account.
             However, it should stay in here for state-debugging purposes. -->
        <ion-button accountMenuToggle>
            <ion-avatar slot="icon-only">
                <ion-icon name="person-circle-outline" color="medium" />
            </ion-avatar>
        </ion-button>
    }

    <div accountMenuToolbarContent class="active-account-menu-header ion-padding">
        @if (activeAccountReference(); as accountReference) {
            <lib-account-avatar
                class="active-account-avatar"
                [width]="48"
                fontSize="2rem"
                [account]="accountReference"
            />
            <div>
                <div class="active-account-label">
                    {{ accountReference.label }}
                </div>
                <div>
                    <small class="active-account-full-name">
                        {{ accountReference.firstName }} {{ accountReference.lastName }}
                        {{ isOffline() ? ' - Offline' : '' }}
                    </small>
                </div>
            </div>
        }
    </div>

    @if (activeAccountReference(); as accountReference) {
        <ion-list accountMenuContent lines="none" class="ion-no-padding">
            <ion-menu-toggle menu="account-menu">
                <ion-item
                    button
                    detail="false"
                    routerLink="/account/details"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-icon src="assets/icons/account_circle.svg" slot="end" />
                    <ion-label>
                        {{ 'navigation.account.profile' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item
                    button
                    detail="false"
                    routerLink="/account/preferences"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-icon src="assets/icons/manage_accounts.svg" slot="end" />
                    <ion-label>
                        {{ 'navigation.account.preferences' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item
                    button
                    detail="false"
                    routerLink="/account/manage"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-icon src="assets/icons/dashboard.svg" slot="end" />
                    <ion-label>
                        {{ 'navigation.account.myPlan' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item
                    button
                    detail="false"
                    routerLink="/account/subscription"
                    routerLinkActive="selected"
                    routerDirection="root"
                    [style.visibility]="accountPlan() === 'BASIC' ? null : 'hidden'"
                >
                    <ion-icon class="plans-icon" src="assets/icons/plan-selection.svg" slot="end" />
                    <ion-label>
                        {{ 'navigation.account.upgrade' | translate }}
                    </ion-label>
                </ion-item>
            </ion-menu-toggle>
        </ion-list>
    }
    <ion-item-group accountMenuContent class="menu-light-contrast">
        <ion-item-divider>
            <ion-label> Other Accounts </ion-label>
        </ion-item-divider>

        @for (accountReference of inactiveAccountReferences(); track accountReference.id) {
            <ng-template #avatarAndLabel>
                <lib-account-avatar
                    [account]="accountReference"
                    [width]="28"
                    fontSize=".7rem"
                    slot="start"
                />
                <ion-label>
                    {{ accountReference.label ?? accountReference.email }}
                </ion-label>
                @if (!accountReference.isLoggedIn) {
                    <ion-text slot="end" color="medium">
                        <small>Logged Out</small>
                    </ion-text>
                }
            </ng-template>
            @if (accountReference.isLoggedIn) {
                <ion-item
                    (click)="setActiveAccount(accountReference.id)"
                    button
                    class="menu-light-contrast"
                    detail="false"
                    lines="none"
                >
                    <ng-container *ngTemplateOutlet="avatarAndLabel"></ng-container>
                </ion-item>
            } @else {
                <ion-item
                    [queryParams]="{ email: accountReference.email, redirect: '/dashboard' }"
                    button
                    class="menu-light-contrast"
                    detail="false"
                    lines="none"
                    routerLink="/account/login"
                >
                    <ng-container *ngTemplateOutlet="avatarAndLabel"></ng-container>
                </ion-item>
            }
        }
        <ion-menu-toggle menu="account-menu">
            <ion-item
                button
                class="menu-light-contrast"
                detail="false"
                lines="none"
                routerLink="/accounts/manage"
            >
                <ion-label>
                    {{ 'navigation.account.manageAccounts' | translate }}
                </ion-label>
                <ion-icon name="people-outline" slot="end" />
            </ion-item>
        </ion-menu-toggle>
        @if (activeAccountReference()?.isLoggedIn) {
            <!-- Todo: click handler to log out selected account -->
            <ion-item
                (click)="logOutAllAccounts()"
                [disabled]="isOffline()"
                button
                detail="false"
                lines="none"
            >
                <ion-icon src="assets/icons/logout.svg" slot="end" />
                <ion-label> {{ 'navigation.account.logOut' | translate }} </ion-label>
            </ion-item>
        }
    </ion-item-group>
</lib-branded-navigation>
