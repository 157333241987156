import { RxStorage } from 'rxdb';
import { inject, InjectionToken, Provider } from '@angular/core';
import { provideAppInitializer } from '@lib/didit-shared-util-miscellaneous-providers';
import { DatabaseService } from './database.service';

export function provideDatabaseService(options: DatabaseServiceOptions): Provider[] {
    return [
        // This allows us to change the type of storage between environments
        // (e.g. development vs. production or even web vs. mobile).
        { provide: DATABASE_SERVICE_OPTIONS_TOKEN, useValue: options },
        provideAppInitializer(createDatabaseInitializer),
    ];
}

function createDatabaseInitializer() {
    const databaseOptions = inject(DATABASE_SERVICE_OPTIONS_TOKEN);
    const databaseService = inject(DatabaseService);

    return async () => {
        await databaseOptions.addPlugins();
        await databaseService.getAppDatabase();
        await databaseService.connectToExistingAccounts();
    };
}

export const DATABASE_SERVICE_OPTIONS_TOKEN = new InjectionToken<DatabaseServiceOptions>(
    'Database Options',
);

export type DatabaseServiceOptions = {
    addPlugins: () => Promise<void> | void;
    getStorage: () => RxStorage<unknown, unknown>;
};
