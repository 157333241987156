import { gql } from 'graphql-tag';
import { Inject, Injectable } from '@angular/core';
import { GRAPHQL_DOMAIN_URL_TOKEN, GraphqlDomain } from '../provide-graphql-configuration';
import { HttpClient } from '@angular/common/http';
import { GraphqlMutation } from './graphql-mutation';
import { FeedbackCreationInput } from '@lib/shared-interface-utility-types';

@Injectable({ providedIn: 'root' })
export class SubmitFeedbackGql extends GraphqlMutation<Variables, string> {
    public override readonly document = gql`
        mutation SubmitFeedback($feedbackInput: FeedbackInput!) {
            submitFeedback(feedbackInput: $feedbackInput)
        }
    `;

    public constructor(
        @Inject(GRAPHQL_DOMAIN_URL_TOKEN) graphqlDomain: GraphqlDomain,
        http: HttpClient,
    ) {
        super(graphqlDomain, http);
    }
}

type Variables = { feedbackInput: FeedbackCreationInput };
