import { Inject, Injectable } from '@angular/core';
import { GraphqlMutation } from './graphql-mutation';
import { GRAPHQL_DOMAIN_URL_TOKEN, GraphqlDomain } from '../provide-graphql-configuration';
import { HttpClient } from '@angular/common/http';
import { gql } from 'graphql-tag';

@Injectable({
    providedIn: 'root',
})
export class UpdateGatewaySubscriptionDetailsGql extends GraphqlMutation<Variables, Result> {
    public override readonly document = gql`
        mutation UpdateGatewaySubscriptionDetails($input: GatewaySubscriptionGqlInput!) {
            updateGatewaySubscriptionDetails(input: $input) {
                success
            }
        }
    `;

    public constructor(
        @Inject(GRAPHQL_DOMAIN_URL_TOKEN) graphqlDomain: GraphqlDomain,
        http: HttpClient,
    ) {
        super(graphqlDomain, http);
    }
}

type Result = Record<'updateGatewaySubscriptionDetails', { success: boolean }>;
type Variables = Record<
    'input',
    {
        paymentGatewayName: string;
        subscriberPlanId: string;
        priceId: string;
    }
>;
