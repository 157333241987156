Object.assign(window, {
    // This is needed for the Amazon-cognito-identity-js and RxDB lib;
    // without it, during bootstrap of the app, you will get a referenceError about global.
    // See https://stackoverflow.com/questions/52175544/angular-amazon-cognito-identity-js-why-am-i-getting-error-global-is-not-defi
    global: window,

    // In addition to the above, RxDB also needs the following.
    // https://github.com/pubkey/rxdb/tree/master/examples/angular#important-parts-when-using-rxdb-with-angular
    process: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        env: { DEBUG: undefined },
        nextTick: (callback: (...parameters: unknown[]) => void, ...parameters: unknown[]) =>
            setTimeout(() => callback(...parameters)),
    },

    /**
     * NOTE: This is all adapted from generating a new ionic 8 angular project on the side
     * and then poking around to find improvements to bring over.
     *
     * By default, zone.js will patch all possible macroTask and DomEvents
     * user can disable parts of macroTask/DomEvents patch by setting following flags
     * because those flags need to be set before `zone.js` being loaded, and webpack
     * will put import in the top of bundle, so user need to create a separate file
     * in this directory (for example: zone-flags.ts), and put the following flags
     * into that file, and then add the following code before importing zone.js.
     * import './zone-flags';
     *
     * The flags allowed in zone-flags.ts are listed here.
     *
     * The following flags will work for all browsers.
     *
     * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
     * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
     * (window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
     *
     *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
     *  with the following flag, it will bypass `zone.js` patch for IE/Edge
     *
     *  (window as any).__Zone_enable_cross_context_check = true;
     */

    /**
     * Prevents Angular change detection from
     * running with certain Web Component callbacks
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __Zone_disable_customElements: true,
});

import 'zone.js';

// Required specifically for RxDB:
// https://github.com/pubkey/rxdb/tree/master/examples/angular#important-parts-when-using-rxdb-with-angular
import 'zone.js/plugins/zone-patch-rxjs';
