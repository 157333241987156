import { Uuid } from '@lib/shared-interface-utility-types';
import { Ask, AskStatus, AssignmentStrategy, RecipientAskStatus } from '@lib/shared-interface-ask';
import { MangoQuery, MangoQuerySelector, MangoQuerySortPart } from 'rxdb';

export type AskFilters = {
    id?: Uuid;
    namedFilter?: keyof typeof NAMED_FILTERS;
    searchText?: string;
    // There will be other filters eventually such as date range
};

export function createAskQuery(accountId: Uuid, filter: AskFilters): MangoQuery<Ask> {
    const selector: MangoQuerySelector<Ask> = { $and: [] };
    const sort: MangoQuerySortPart<Ask>[] = [
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { 'whenRequestedBy.date': 'asc' },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { 'whenRequestedBy.time': 'asc' },
        { createdAt: 'asc' },
    ];

    if (filter.id) {
        selector.$and?.push({ id: filter.id });
    }
    if (filter.namedFilter) {
        const getFilter = NAMED_FILTERS[filter.namedFilter];
        selector.$and?.push(...getFilter(accountId));
    }
    if (filter.searchText) {
        const $regex = getSearchPattern(filter.searchText);
        const $options = 'i';

        selector.$and?.push({
            $or: [
                { title: { $regex, $options } },
                { description: { $regex, $options } },
                // Todo: Creator name/email is not available at this time.
                // { creator: { name: { $regex, $options } } },
                // { creator: { email: { $regex, $options } } },
                { recipients: { $elemMatch: { name: { $regex, $options } } } },
                { recipients: { $elemMatch: { email: { $regex, $options } } } },
            ],
        });
    }

    return { selector, sort };
}

export const NAMED_FILTERS = {
    trash: (accountId: Uuid) => [{ trashedAt: { $exists: true } }, { creatorId: accountId }],
    watching: (accountId: Uuid) => [
        { trashedAt: { $exists: false } },
        { currentStatus: AskStatus.OPEN },
        {
            recipients: {
                $elemMatch: {
                    accountId,
                    watcher: true,
                    $and: [
                        { currentStatus: { $exists: true } },
                        { currentStatus: { $ne: RecipientAskStatus.DECLINED } },
                    ],
                },
            },
        },
        {
            $or: [
                { creatorId: accountId },
                { recipients: { $elemMatch: { accountId, watcher: true } } },
            ],
        },
    ],
    todo: (accountId: Uuid) => [
        { trashedAt: { $exists: false } },
        { currentStatus: AskStatus.OPEN },
        {
            recipients: {
                $elemMatch: {
                    accountId,
                    assignee: true,
                    $and: [
                        { currentStatus: { $exists: true } },
                        {
                            currentStatus: {
                                $nin: [
                                    RecipientAskStatus.DECLINED,
                                    RecipientAskStatus.COMPLETED,
                                    RecipientAskStatus.CANCELED,
                                ],
                            },
                        },
                    ],
                },
            },
        },
    ],
    drafts: (accountId: Uuid) => [
        { trashedAt: { $exists: false } },
        { currentStatus: AskStatus.DRAFT },
        { creatorId: accountId },
    ],
    done: (accountId: Uuid) => [
        { trashedAt: { $exists: false } },
        {
            $or: [
                {
                    $and: [{ creatorId: accountId }, { currentStatus: 'COMPLETED' }],
                },
                {
                    recipients: {
                        $elemMatch: {
                            accountId,
                            assignee: true,
                            $and: [
                                { currentStatus: { $exists: true } },
                                {
                                    currentStatus: RecipientAskStatus.COMPLETED,
                                },
                            ],
                        },
                    },
                },
            ],
        },
    ],
    sent: (accountId: Uuid) => [
        { trashedAt: { $exists: false } },
        { currentStatus: { $ne: AskStatus.DRAFT } },
        { creatorId: accountId },
    ],
    messages: (accountId: Uuid) => [
        { trashedAt: { $exists: false } },
        { currentStatus: { $ne: AskStatus.DRAFT } },
        {
            $or: [
                // if EVERYONE or COLLABORATIVE, then the recipient must be an assignee or watcher
                // and have no currentStatus or currentStatus is not declined
                {
                    $and: [
                        {
                            assignmentStrategy: {
                                $in: [
                                    AssignmentStrategy.EVERYONE,
                                    AssignmentStrategy.COLLABORATIVE,
                                ],
                            },
                        },
                        {
                            recipients: {
                                $elemMatch: {
                                    accountId,
                                    $and: [
                                        {
                                            $or: [{ currentStatus: { $exists: false } }],
                                        },
                                        {
                                            $or: [{ assignee: true }, { watcher: true }],
                                        },
                                    ],
                                },
                            },
                        },
                    ],
                },
                // if ANYONE, then the recipient must be an assignee or watcher and have no currentStatus
                // and there must be no other recipient assignees who have declined or have no currentStatus
                {
                    $and: [
                        { assignmentStrategy: AssignmentStrategy.ANYONE },
                        {
                            recipients: {
                                $elemMatch: {
                                    accountId,
                                    $and: [
                                        {
                                            $or: [{ currentStatus: { $exists: false } }],
                                        },
                                        {
                                            $or: [{ assignee: true }, { watcher: true }],
                                        },
                                    ],
                                },
                            },
                        },
                        {
                            recipients: {
                                $not: {
                                    $elemMatch: {
                                        accountId: { $ne: accountId },
                                        assignee: true,
                                        currentStatus: { $eq: RecipientAskStatus.OPEN },
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        },
    ],
} satisfies Record<string, (accountId: Uuid) => MangoQuerySelector<Ask>[]>;

export function getSearchPattern(searchText: string): string {
    // An attempt to match partial words in order of being written.
    // e.g. searchText = " hel   wor " could match the text "Hello World!"
    const whitespacePattern = /\s+/;
    // e.g. This gives us ['hel', 'wor'].
    const escapedSearchText = searchText.replaceAll(specialCharacters, String.raw`\$1`);
    const searchTerms = escapedSearchText.trim().split(whitespacePattern);
    // The capturing groups are not strictly necessary,
    // but it could later allow us to highlight the matched words when presenting results.
    // e.g. This finally gives us '(hel).*(wor)'.
    return searchTerms.map((term) => `(${term})`).join('.*');
}

const specialCharacters = /([!$()*+.?[\\\]^{|}])/g;
