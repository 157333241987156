import { inject, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular/standalone';
import { WINDOW_TOKEN } from '@lib/didit-shared-util-miscellaneous-providers';

@Injectable({
    providedIn: 'root',
})
export class AppUpdateService implements OnDestroy {
    private readonly swUpdate = inject(SwUpdate);
    private readonly toastController = inject(ToastController);
    private readonly window = inject(WINDOW_TOKEN);

    private subscription?: Subscription;
    private updateCheckTimeout?: ReturnType<typeof setTimeout>;
    private autoRestartTimeout?: ReturnType<typeof setTimeout>;

    public initialize(durations: AppUpdateDurations) {
        this.subscription = this.swUpdate.versionUpdates.subscribe((event) => {
            if (event.type === 'NO_NEW_VERSION_DETECTED') {
                this.setVersionCheckTimeout(durations.checkForUpdate);
                return;
            }
            if (event.type === 'VERSION_READY') {
                void this.presentRestartPrompt(durations);
                return;
            }
            if (event.type === 'VERSION_INSTALLATION_FAILED') {
                console.error(
                    'An error occurred while installing the new application version.',
                    event.error,
                );
                return;
            }
        });
    }

    public ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    private setVersionCheckTimeout(minutes: number) {
        if (this.updateCheckTimeout) {
            clearTimeout(this.updateCheckTimeout);
        }
        const timeoutMilliseconds = 1000 * 60 * minutes;
        this.updateCheckTimeout = setTimeout(
            () => void this.swUpdate.checkForUpdate(),
            timeoutMilliseconds,
        );
    }

    private async presentRestartPrompt(durations: AppUpdateDurations) {
        // Below this screen size, stacked without an icon looks better.
        // If we had better access to shadow DOM,
        // we could change the flex-direction of the button container instead.
        const toast = await this.toastController.create({
            header: 'Update Available',
            message:
                'Click to install now. Your data will be saved. App will auto-update in 30 seconds.',
            icon: '/assets/logos/logo-icon-only.svg',
            animated: true,
            color: 'light',
            position: 'bottom',
            cssClass: 'update-available-toast',
            duration: 1000 * 60 * durations.restartReminder,
            buttons: [
                {
                    role: 'cancel',
                    icon: 'close',
                    side: 'end',
                    handler: () => {
                        if (this.autoRestartTimeout) clearTimeout(this.autoRestartTimeout);
                        this.setVersionCheckTimeout(durations.restartReminder);
                    },
                },
            ],
        });

        for (const item of toast.shadowRoot?.querySelectorAll('button') ?? []) {
            // We don't want these buttons to trigger an outer click event, which we handle below.
            item.addEventListener('click', (clickEvent) => clickEvent.stopPropagation());
        }

        toast.addEventListener('click', () => this.window.location.reload());

        await toast.present();

        this.autoRestartTimeout = setTimeout(
            () => this.window.location.reload(),
            durations.autoUpdateDelay * 60 * 1000,
        );
    }
}

// These are all in minutes.
export type AppUpdateDurations = {
    checkForUpdate: number;
    restartReminder: number;
    autoUpdateDelay: number;
};
