/**
 * Email is an alias for a string.
 *
 * While there may be a way to eventually create a more restricted type,
 * this serves for now to signal developer intent.
 */
export type Email = string;

/**
 * Phone is an alias for a string.
 *
 * This always includes the country code
 * and should follow the [E.164 format](https://en.wikipedia.org/wiki/E.164).
 * While there may be a way to eventually create a more restricted type,
 * this serves for now to signal developer intent.
 */
export type Phone = string;

/**
 * Uuid is an alias for a string.
 *
 * This is most likely a V4 UUID unless the specification evolves again.
 *
 * This should follow the UUID specification found at [ietf.org](https://www.ietf.org/rfc/rfc4122.txt)
 *
 * While there may be a way to eventually create a more restricted type,
 * this serves for now to signal developer intent.
 */
export type Uuid = string;
