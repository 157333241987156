import { removeNulls, Uuid } from '@lib/shared-interface-utility-types';
import { AssignmentsCollection } from '../get-account-database';
import {
    getPullQueryBuilder,
    getPullStreamQueryBuilder,
    getPushQueryBuilder,
} from '../account-database-replication-helpers';
import { REPLICATION_CHECKPOINT_GQL_FRAGMENT } from '../../shared/replication-checkpoint-gql-fragment';

export function createAssignmentReplicationOptions(
    accountId: Uuid,
    collection: AssignmentsCollection,
) {
    const limit = 50;
    const pullQueryBuilder = getPullQueryBuilder(PULL_GQL, { accountId, limit });
    const pushQueryBuilder = getPushQueryBuilder(PUSH_GQL, { accountId });
    const pullStreamQueryBuilder = getPullStreamQueryBuilder(STREAM_GQL, { accountId });

    return {
        collection,
        pull: {
            queryBuilder: pullQueryBuilder,
            streamQueryBuilder: pullStreamQueryBuilder,
            batchSize: limit,
            modifier: removeNulls,
            responseModifier: removeNulls,
            includeWsHeaders: true,
        },
        push: {
            queryBuilder: pushQueryBuilder,
            batchSize: limit,
            modifier: removeNulls,
            responseModifier: removeNulls,
        },
        deletedField: 'deleted',
        live: true,
        // 5 seconds
        retryTime: 1000 * 5,
        waitForLeadership: true,
        autoStart: true,
    };
}

const DOCUMENT_GQL_FRAGMENT = /* GraphQL */ `
    fragment ReplicatedAssignmentProperties on ReplicatedAssignment {
        id
        askId
        creatorId
        contactId
        accountId
        resolutionSummary
        statusChanges {
            timestamp
            status
        }
        currentStatus
        createdAt
        updatedAt
        deleted
    }
`;

const PULL_GQL = /* GraphQL */ `
    ${REPLICATION_CHECKPOINT_GQL_FRAGMENT}
    ${DOCUMENT_GQL_FRAGMENT}
    query PullAssignments($accountId: ID!, $checkpoint: ReplicationCheckpointInput, $limit: Int) {
        assignmentsPull(accountId: $accountId, checkpoint: $checkpoint, limit: $limit) {
            checkpoint {
                ...ReplicationCheckpointProperties
            }
            documents {
                ...ReplicatedAssignmentProperties
            }
        }
    }
`;

const PUSH_GQL = /* GraphQL */ `
    ${DOCUMENT_GQL_FRAGMENT}
    mutation PushAssignments($accountId: ID!, $rows: [AssignmentReplicationPushRow!]!) {
        pushAssignments(accountId: $accountId, rows: $rows) {
            ...ReplicatedAssignmentProperties
        }
    }
`;

const STREAM_GQL = /* GraphQL */ `
    ${REPLICATION_CHECKPOINT_GQL_FRAGMENT}
    ${DOCUMENT_GQL_FRAGMENT}
    subscription StreamAssignments($accountId: ID!, $authorization: String!) {
        assignmentsChanged(accountId: $accountId, authorization: $authorization) {
            checkpoint {
                ...ReplicationCheckpointProperties
            }
            documents {
                ...ReplicatedAssignmentProperties
            }
        }
    }
`;
