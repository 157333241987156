import { inject, Injectable } from '@angular/core';
import { map, Observable, shareReplay, switchMap } from 'rxjs';
import { SubscriberPlan } from '@lib/shared-interface-subscriber-plan';
import { mapArray } from '@lib/shared-util-rxjs';
import { DatabaseService } from '@lib/didit-shared-data-database-service';

@Injectable({
    providedIn: 'root',
})
export class SubscriberPlansService {
    private readonly databaseService = inject(DatabaseService);

    public readonly plans$: Observable<SubscriberPlan[]> = this.databaseService.appDatabase$.pipe(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        map(({ subscriber_plans }) => subscriber_plans.find()),
        switchMap((query) => query.$),
        mapArray((document) => document.toMutableJSON()),
        map((plans) => plans.sort((a, b) => a.order - b.order)),
        shareReplay(1),
    );
}
