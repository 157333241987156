import { DateTimeZone, Email, Uuid } from '@lib/shared-interface-utility-types';
import {
    AcceptanceType,
    AskStatus,
    AssignmentStrategy,
    RecipientAskStatus,
} from './ask-enums.interface';
import { ReplicationDeletable } from '@lib/shared-interface-rxdb-replication-types';

export type FullAsk = ReplicatedAsk;

export type ReplicatedAsk = Ask & ReplicationDeletable;

/**
 * Properties of an ask shared between the client and server.
 */
export type Ask = {
    /**
     * Unique ID for this ask.
     */
    id: Uuid;
    /**
     * The id for the account which created this ask.
     */
    creatorId: Uuid;
    /**
     * The given name of the creator. This is mainly a convenience field when the creator is not yet a contact for the recipient.
     */
    creatorFirstName: string;
    /**
     * The family name of the creator. This is mainly a convenience field when the creator is not yet a contact for the recipient.
     */
    creatorLastName: string;
    /**
     * The date and time when this ask was created.
     */
    createdAt: number;
    /**
     * The date and time when this ask was last updated.
     */
    updatedAt: number;
    /**
     * A title to quickly denote the topic of this ask.
     */
    title: string;
    /**
     * A fuller description further explaining the title of this ask.
     */
    description?: string;
    /**
     * The individual requesting this ask if different from the creator.
     *
     * A requester is essentially a primary watcher.
     */
    requester?: Requester;
    /**
     * The list of recipients and data about their response to this ask.
     */
    recipients: Recipient[];
    /**
     * The approximate date by which the requester wishes this ask to be completed.
     */
    whenRequestedBy?: DateTimeZone;
    /**
     * Recurrence pattern for the ask's whenRequestedBy date, if applicable
     */
    recurrencePattern?: string;
    /**
     * The sequence of changes to the status of this ask.
     *
     * Defaults to `AskStatus.DRAFT`.
     */
    statusChanges: AskStatusChange[];
    /**
     * Whether the ask has been sent.
     */
    sentAt?: number;
    /**
     * When the ask was trashed.
     *
     */
    trashedAt?: number;
    /**
     * When the ask was marked completed.
     *
     * This is a helper for sorting purposes. If the creator alters the status from COMPLETED,
     * this field should be cleared out again.
     */
    completedAt?: number;
    /**
     * The AskStatus Enum value of the latest StatusChange in the statusChanges array.
     */
    currentStatus: AskStatus;
    /**
     * The strategy for creating assignments.
     */
    assignmentStrategy: AssignmentStrategy;
};

/**
 * Information about a requester in relation to a specific ask.
 */
export type Requester = {
    /**
     * The account id of the requester.
     */
    accountId: Uuid;
    /**
     * An optional response regarding how the requester has explicitly responded to their role.
     */
    acceptance?: AcceptanceType;
};

/**
 * Information a recipient in relation to a specific ask.
 */
export type Recipient = {
    /**
     * The account id of the assignee.
     */
    accountId?: Uuid;
    /**
     * The ID of the contact from the creator's contact list.
     */
    contactId?: Uuid;
    /**
     * The ID of the assignment created when the recipient accepts the ask.
     */
    assignmentId?: Uuid;
    /**
     * The first name of the assignee copied from their account at the time of assignment.
     * Note that to be valid, we require at least one of First or Last names to be present.
     */
    firstName?: string;
    /**
     * The last name of the assignee copied from their account at the time of assignment.
     * Note that to be valid, we require at least one of First or Last names to be present.
     */
    lastName?: string;
    /**
     * The email of the assignee copied from their account at the time of assignment.
     */
    email?: Email;
    /**
     * Whether the recipient is a watcher.
     */
    watcher: boolean;
    /**
     * Whether the recipient is an assignee.
     */
    assignee: boolean;
    /**
     * Whether the recipient is an editor.
     */
    editor: boolean;
    /**
     * An approximate date by which the recipient intends to complete the assigned ask.
     *
     * Only meaningful for assignees.
     */
    whenPlannedBy?: DateTimeZone;
    /**
     * The sequence of changes to the status of this ask from this recipient's perspective.
     */
    statusChanges: RecipientAskStatusChange[];
    /**
     * The most recent status added to the history of status changes.
     */
    currentStatus?: RecipientAskStatus;
    /**
     * A short message about why the recipient declined the ask
     * which should only be there if they actually declined.
     */
    reasonDeclined?: string;
    /**
     * Whether the assignee considers the assigned ask to be complete from their perspective.
     */
    completed: boolean;
};

/**
 * A change to the status of an ask.
 */
export type AskStatusChange = {
    /**
     * The date and time when this status change was made.
     */
    timestamp: number;
    /**
     * The status of the ask at the time of this status change.
     */
    status: AskStatus;
};

/**
 * A change to the status of an ask from the perspective of a recipient.
 */
export type RecipientAskStatusChange = {
    /**
     * The date and time when this status change was made.
     */
    timestamp: number;
    /**
     * The recipient status of the ask at the time of this status change.
     */
    status: RecipientAskStatus;
};

// ID & created at cannot be changed. Updated at will always be overwritten.
export type AskUpdate = Partial<Omit<Ask, 'id' | 'creatorId' | 'createdAt' | 'updatedAt'>>;
