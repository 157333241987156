import { ActiveAccountIdStore } from './active-account-id-store.service';
import { AccountReference } from '@lib/didit-accounts-interface-account-reference';
import { Uuid } from '@lib/shared-interface-utility-types';
import { firstValueFrom, from, map, Observable, shareReplay, switchMap } from 'rxjs';
import { DatabaseService, removeAccountDatabase } from '@lib/didit-shared-data-database-service';
import { mapArray, switchMapIfDefined } from '@lib/shared-util-rxjs';
import { inject, Injectable } from '@angular/core';
import { Account, AccountUpdate } from '@lib/shared-interface-account';
import { Router } from '@angular/router';
import { MangoQueryNoLimit } from 'rxdb';

@Injectable({ providedIn: 'root' })
export class AccountsService {
    private readonly activeAccountIdStore = inject(ActiveAccountIdStore);
    private readonly databaseService = inject(DatabaseService);
    private readonly router = inject(Router);

    /**
     * Watch changes to all account references.
     */
    public readonly accountReferences$: Observable<AccountReference[]> =
        this.databaseService.appDatabase$.pipe(
            // eslint-disable-next-line @typescript-eslint/naming-convention
            map(({ account_references }) => account_references.find()),
            switchMap((query) => query.$),
            mapArray((document) => document.toMutableJSON()),
            // Avoid running through previous steps again on late subscription.
            shareReplay(1),
        );

    public readonly activeAccountReference$: Observable<AccountReference | undefined> =
        this.activeAccountIdStore.item$.pipe(
            switchMapIfDefined((id) => this.watchReferenceById(id)),
            shareReplay(1),
        );

    /**
     * Observable that emits whenever the active account is updated.
     */
    public readonly activeAccount$: Observable<Account | undefined> =
        this.activeAccountIdStore.item$.pipe(
            switchMapIfDefined((id) => this.watchById(id)),
            shareReplay(1),
        );

    /**
     * Adds an account.
     *
     * Makes the new account active, and updates the accounts$ and activeAccount$ observables.
     * If the account reference already exists, nothing happens.
     * Adding an account reference will automatically trigger creating an account database.
     */
    public async add(accountReference: AccountReference): Promise<void> {
        const { id } = accountReference;
        const database = await this.databaseService.getAppDatabase();
        const collection = database.account_references;
        const selectByIdQuery: MangoQueryNoLimit<AccountReference> = { selector: { id } };
        const query = collection.findOne(selectByIdQuery);
        const existingAccountReference = await query.exec();

        if (existingAccountReference != undefined) return;

        await collection.insert(accountReference);
        await this.setActive(accountReference.id);
    }

    public async findAccountReferenceByAuthenticationId(
        authenticationId: string,
    ): Promise<AccountReference | undefined> {
        const database = await this.databaseService.getAppDatabase();
        const selectByAuthenticationIdQuery: MangoQueryNoLimit<AccountReference> = {
            selector: { authenticationId },
        };
        const query = database.account_references.findOne(selectByAuthenticationIdQuery);
        const document = await query.exec();

        return document ?? undefined;
    }

    public async findFirstAccountReference(): Promise<AccountReference | undefined> {
        const database = await this.databaseService.getAppDatabase();
        const query = database.account_references.findOne();
        const document = await query.exec();

        return document ?? undefined;
    }

    /**
     * Update an account's details.
     *
     * If successful, the accounts$ observable is expected to update.
     */
    public async patch(id: Uuid, patch: AccountUpdate): Promise<void> {
        const database = await this.databaseService.getAccountDatabase(id);
        // There should always be only one account document in the collection.
        const query = database.account.findOne();
        const document = await query.exec();

        if (document == undefined)
            throw new Error(`Account with id ${id} does not exist. Cannot update.`);

        // Incremental methods help avoid conflicts.
        await document.incrementalPatch(patch);
    }

    /**
     * Sets the last active account.
     *
     * When a user makes an account the active account, store this information on the account.
     * Updates the activeAccount$ observable.
     */
    public async setActive(id: Uuid | undefined): Promise<void> {
        await this.activeAccountIdStore.update(id);
    }

    public async clearAllLastViewedPages(): Promise<void> {
        const accounts = await firstValueFrom(this.accountReferences$);
        for (const account of accounts) removeRedirectUrl(account.id);
    }

    public async saveLastViewedPage(currentPage: string): Promise<void> {
        const id = await firstValueFrom(this.activeAccountIdStore.item$);
        if (!id) return;

        const accountUrlPattern = /account\//;
        if (accountUrlPattern.test(currentPage)) return;

        setRedirectUrl(id, currentPage);
    }

    public async removeAccount(accountReference: AccountReference): Promise<void> {
        const activeAccountId = this.activeAccountIdStore.storedId;
        if (accountReference.id !== activeAccountId) {
            await removeAccountDatabase(accountReference.id);
            return;
        }
        const accountReferences = await firstValueFrom(this.accountReferences$);
        const nextAccount = accountReferences.find((account) => account.id !== activeAccountId);
        if (nextAccount) {
            await this.setActive(nextAccount.id);
            await removeAccountDatabase(accountReference.id);
            return;
        }
        await removeAccountDatabase(accountReference.id);
        await this.router.navigate(['/account/login']);
    }

    private watchById(id: Uuid): Observable<Account | undefined> {
        const accountDatabase = this.databaseService.getAccountDatabase(id);
        const database$ = from(accountDatabase);

        return database$.pipe(
            map(({ account }) => account.findOne()),
            switchMap((query) => query.$),
            map((document) => document?.toMutableJSON()),
        );
    }

    private watchReferenceById(id: Uuid): Observable<AccountReference | undefined> {
        return this.databaseService.appDatabase$.pipe(
            // eslint-disable-next-line @typescript-eslint/naming-convention
            map(({ account_references }) => account_references.findOne({ selector: { id } })),
            switchMap((query) => query.$),
            map((document) => document?.toMutableJSON()),
        );
    }
}

function setRedirectUrl(accountId: Uuid, url: string) {
    const storageKey = getRedirectUrlStorageKey(accountId);
    sessionStorage.setItem(storageKey, url);
}

function removeRedirectUrl(accountId: Uuid) {
    const storageKey = getRedirectUrlStorageKey(accountId);
    sessionStorage.removeItem(storageKey);
}

function getRedirectUrlStorageKey(accountId: Uuid): string {
    return `AccountsService.${accountId}.lastViewedPage`;
}
