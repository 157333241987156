import {
    Format,
    JsonSchema,
    MaxLength,
    Optional,
    Required,
    Type,
} from 'ts-decorator-json-schema-generator';
import { Email, Phone, Uuid } from '@lib/shared-interface-utility-types';
import { generateRxdbSchema } from '../../shared/generate-rxdb-schema';
import { Contact } from '@lib/shared-interface-contact';
import { UuidFormat } from '../../shared/uuid-format.decorator';

@JsonSchema({
    title: 'Contact Schema',
    description: 'A user contact.',
})
class ContactSchema implements Contact {
    @Required()
    @UuidFormat()
    public readonly id!: Uuid;

    @Optional()
    @Format('email')
    // Email will be indexed.
    // Indexing requires a maximum length: https://rxdb.info/rx-schema.html#indexes.
    // Email max length is discussed here: https://stackoverflow.com/a/574698.
    @MaxLength(254)
    public readonly email?: Email;

    @Optional()
    public readonly firstName?: string;

    @Optional()
    public readonly lastName?: string;

    @Optional()
    public readonly companyName?: string;

    @Optional()
    public readonly phone?: Phone;

    @Required()
    @Type('integer')
    public readonly updatedAt!: number;
}

export const CONTACT_SCHEMA = generateRxdbSchema(ContactSchema, {
    primaryKey: 'id',
    // If you do not increment this version, you may need to wipe your database.
    // Once in production, incrementing this number will require migrations.
    version: 0,
    // Key compression doesn't work with fake-indexeddb, so it is turned off for now.
    // If we need key compression at some point, we can probably selectively turn it off for tests.
    keyCompression: false,
});
