import {
    addRxPlugin,
    type RxDocumentData,
    type RxJsonSchema,
    type RxValidationError,
    wrappedValidateStorageFactory,
} from 'rxdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { addProductionPlugins } from './rxdb.prod';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';

export function addDevelopmentPlugins() {
    // RxDB development plugins can be imported and enabled in this file.
    console.log(`RxDB development plugins loading ...`);

    addProductionPlugins();

    // Adapted from https://rxdb.info/dev-mode.html#usage-with-angular.
    // Note that this needs to happen before the first database is created.
    addRxPlugin(RxDBDevModePlugin);

    console.log(`RxDB development plugins loaded !`);
}

export const getDevelopmentStorage = () => wrapValidateAjvStorage({ storage: getRxStorageDexie() });

// This adapted from https://github.com/pubkey/rxdb/blob/master/src/plugins/validate-ajv/index.ts
// so that we could add the ajv-formats plugin to ajv.
const ajv = new Ajv({ strict: false });
addFormats(ajv);

function getValidator(schema: RxJsonSchema<unknown>) {
    const validator = ajv.compile(schema);

    return (documentData: RxDocumentData<unknown>) => {
        const isValid = validator(documentData);
        return isValid ? [] : (validator.errors as unknown as RxValidationError[]);
    };
}

export const wrapValidateAjvStorage = wrappedValidateStorageFactory(getValidator, 'ajv');
