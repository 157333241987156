import { SubscriberPlanCollection } from '../get-app-database';
import {
    getAppPullQueryBuilder,
    getAppPullStreamQueryBuilder,
} from '../app-database-replication-helpers';
import { REPLICATION_CHECKPOINT_GQL_FRAGMENT } from '../../shared/replication-checkpoint-gql-fragment';
import { removeNulls } from '@lib/shared-interface-utility-types';

export function createSubscriberPlanReplicationOptions(collection: SubscriberPlanCollection) {
    const limit = 10;
    const pullQueryBuilder = getAppPullQueryBuilder(PULL_SUBSCRIPTION_PLANS_GQL, { limit });
    const pullStreamQueryBuilder = getAppPullStreamQueryBuilder(STREAM_SUBSCRIPTION_PLANS_GQL);

    return {
        collection,
        pull: {
            queryBuilder: pullQueryBuilder,
            streamQueryBuilder: pullStreamQueryBuilder,
            batchSize: limit,
            modifier: removeNulls,
            responseModifier: removeNulls,
            includeWsHeaders: true,
        },
        deletedField: 'deleted',
        live: true,
        // 5 seconds
        retryTime: 1000 * 5,
        waitForLeadership: true,
        autoStart: true,
    };
}

const DOCUMENT_GQL_FRAGMENT = /* GraphQL */ `
    fragment ReplicatedSubscriberPlanProperties on ReplicatedSubscriberPlan {
        id
        updatedAt
        createdAt
        default
        deleted
        description
        title
        type
        order
        pricing {
            id
            price
            renewalStrategy
        }
    }
`;

const PULL_SUBSCRIPTION_PLANS_GQL = /* GraphQL */ `
    ${REPLICATION_CHECKPOINT_GQL_FRAGMENT}
    ${DOCUMENT_GQL_FRAGMENT}
    query PullSubscriberPlans($checkpoint: ReplicationCheckpointInput, $limit: Int) {
        subscriberPlansPull(checkpoint: $checkpoint, limit: $limit) {
            checkpoint {
                ...ReplicationCheckpointProperties
            }
            documents {
                ...ReplicatedSubscriberPlanProperties
            }
        }
    }
`;

const STREAM_SUBSCRIPTION_PLANS_GQL = /* GraphQL */ `
    ${REPLICATION_CHECKPOINT_GQL_FRAGMENT}
    ${DOCUMENT_GQL_FRAGMENT}
    subscription StreamSubscriptionPlans {
        subscriberPlansChanged {
            checkpoint {
                ...ReplicationCheckpointProperties
            }
            documents {
                ...ReplicatedSubscriberPlanProperties
            }
        }
    }
`;
