import { removeNulls, Uuid } from '@lib/shared-interface-utility-types';
import { ContactsCollection } from '../get-account-database';
import {
    getPullQueryBuilder,
    getPullStreamQueryBuilder,
    getPushQueryBuilder,
} from '../account-database-replication-helpers';
import { REPLICATION_CHECKPOINT_GQL_FRAGMENT } from '../../shared/replication-checkpoint-gql-fragment';

export function createContactReplicationOptions(accountId: Uuid, collection: ContactsCollection) {
    const limit = 50;
    const pullQueryBuilder = getPullQueryBuilder(PULL_CONTACTS_GQL, { accountId, limit });
    const pushQueryBuilder = getPushQueryBuilder(PUSH_CONTACTS_GQL, { accountId });
    const pullStreamQueryBuilder = getPullStreamQueryBuilder(STREAM_CONTACTS_GQL, { accountId });

    return {
        collection,
        pull: {
            queryBuilder: pullQueryBuilder,
            streamQueryBuilder: pullStreamQueryBuilder,
            batchSize: limit,
            modifier: removeNulls,
            responseModifier: removeNulls,
            includeWsHeaders: true,
        },
        push: {
            queryBuilder: pushQueryBuilder,
            batchSize: limit,
            modifier: removeNulls,
            responseModifier: removeNulls,
        },
        deletedField: 'deleted',
        live: true,
        // 5 seconds
        retryTime: 1000 * 5,
        waitForLeadership: true,
        autoStart: true,
    };
}

const DOCUMENT_GQL_FRAGMENT = /* GraphQL */ `
    fragment ReplicatedContactProperties on ReplicatedContact {
        # common to replication
        id
        updatedAt
        deleted
        # specific to account
        email
        firstName
        lastName
        companyName
        phone
    }
`;

const PULL_CONTACTS_GQL = /* GraphQL */ `
    ${REPLICATION_CHECKPOINT_GQL_FRAGMENT}
    ${DOCUMENT_GQL_FRAGMENT}
    query PullContacts($accountId: ID!, $checkpoint: ReplicationCheckpointInput, $limit: Int) {
        contactsPull(accountId: $accountId, checkpoint: $checkpoint, limit: $limit) {
            checkpoint {
                ...ReplicationCheckpointProperties
            }
            documents {
                ...ReplicatedContactProperties
            }
        }
    }
`;

const PUSH_CONTACTS_GQL = /* GraphQL */ `
    ${DOCUMENT_GQL_FRAGMENT}
    mutation PushContacts($accountId: ID!, $rows: [ContactReplicationPushRow!]!) {
        pushContacts(accountId: $accountId, rows: $rows) {
            ...ReplicatedContactProperties
        }
    }
`;

const STREAM_CONTACTS_GQL = /* GraphQL */ `
    ${REPLICATION_CHECKPOINT_GQL_FRAGMENT}
    ${DOCUMENT_GQL_FRAGMENT}
    subscription StreamContacts($accountId: ID!, $authorization: String!) {
        contactsChanged(accountId: $accountId, authorization: $authorization) {
            checkpoint {
                ...ReplicationCheckpointProperties
            }
            documents {
                ...ReplicatedContactProperties
            }
        }
    }
`;
