import { Component, computed, HostBinding, input } from '@angular/core';
import { IonAvatar, IonIcon } from '@ionic/angular/standalone';
import { Account } from '@lib/shared-interface-account';

@Component({
    standalone: true,
    selector: 'lib-account-avatar',
    templateUrl: './account-avatar.component.html',
    styleUrls: ['./account-avatar.component.scss'],
    imports: [IonAvatar, IonIcon],
})
export class AccountAvatarComponent {
    public readonly account = input<Pick<Account, 'avatar' | 'label'> | undefined>();
    public readonly width = input<number>();
    public readonly fontSize = input('1.2rem');
    public readonly backgroundColor = input('var(--ion-color-primary)');

    protected readonly imageSource = computed(() => this.account()?.avatar);
    protected readonly titleInitial = computed(() =>
        this.account()?.label?.charAt(0).toUpperCase(),
    );

    @HostBinding('style.height.px')
    @HostBinding('style.width.px')
    protected get heightAndWidth() {
        return this.width();
    }
}
