import { Uuid } from './aliases.interface';

export type Feedback = {
    /**
     * Id of the feedback entry
     */
    id: Uuid;

    /**
     * Title entered by the user giving feedback
     */
    title: string;

    /**
     * Description entered by the user giving feedback
     */
    description: string;

    /**
     * Id of the account providing the feedback
     */
    accountId: Uuid;

    /**
     * Date the feedback was provided
     */
    createdAt: number;
};

export type FeedbackCreationInput = Omit<Feedback, 'id' | 'accountId' | 'createdAt'>;
